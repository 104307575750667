import { createApp } from 'vue';
import App from './App.vue';
import { createRouter, createWebHashHistory } from 'vue-router';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
library.add(fas);
library.add(far);

import MDropdownList from './components/combobox/MDropdownList.vue';
import MCombobox from './components/combobox/MCombobox.vue';
import MInput from './components/input/MInput.vue';
import MInputMoney from './components/input/MInputMoney.vue';
import MInputTime from './components/input/MInputTime.vue';
import MButton from './components/button/MButton.vue';
import MDialog from './components/dialog/MDialog.vue';
import MDialogTimeCount from './components/dialog/MDialogTimeCount.vue';
import MIcon from './components/icon/MIcon.vue';
import MPaging from './components/paging/MPaging.vue';
import MTable from './components/table/MTable.vue';
import MToastMessage from './components/toast/MToastMessage.vue';
import MLoadingClient from './components/loading/MLoadingClient.vue';
import MChartDoughnutEcharts from './components/chart/MChartDoughnutEcharts.vue';

const app = createApp(App);

app.component('MIcon', MIcon);
app.component('MPaging', MPaging);
app.component('MButton', MButton);
app.component('MDropdownList', MDropdownList);
app.component('MCombobox', MCombobox);
app.component('MInput', MInput);
app.component('MInputMoney', MInputMoney);
app.component('MInputTime', MInputTime);
app.component('MTable', MTable);
app.component('MDialog', MDialog);
app.component('MDialogTimeCount', MDialogTimeCount);
app.component('MToastMessage', MToastMessage);
app.component('MLoadingClient', MLoadingClient);
app.component('MChartDoughnutEcharts', MChartDoughnutEcharts);

app.component('font-awesome-icon', FontAwesomeIcon);

import ToastPlugin from './components/toast/ToastPlugin';
import MEnum from './js/enum';
import MResource from './js/resource';

import { TippyPlugin } from 'tippy.vue';
import VueClickAway from 'vue3-click-away';
import VCalendar from 'v-calendar';
import 'v-calendar/dist/style.css';
import VueLazyLoad from 'vue3-lazyload';

app.config.globalProperties.$MEnum = MEnum;
app.config.globalProperties.$MResource = MResource;

const routes = [
    {
        path: '/',
        name: 'dashboard',
        components: {
            routerAdmin: () => import('./view/overview/OverviewGame.vue'),
        },
    },
    {
        path: '/account',
        name: 'account',
        components: {
            routerAdmin: () => import('./view/account/AccountList.vue'),
        },
    },
    {
        path: '/history',
        name: 'history',
        components: {
            routerAdmin: () => import('./view/history/HistoryList.vue'),
        },
    },
    {
        path: '/feedback',
        name: 'feedback',
        components: {
            routerAdmin: () => import('./view/feedback/FeedbackList.vue'),
        },
    },
    {
        path: '/report',
        name: 'report',
        components: {
            routerAdmin: () => import('./view/report/ReportChatList.vue'),
        },
    },
    {
        path: '/tracking',
        name: 'tracking',
        components: {
            routerAdmin: () => import('./view/tracking/TrackingList.vue'),
        },
    },
    {
        path: '/bot',
        name: 'bot',
        components: {
            routerAdmin: () => import('./view/bot/BotList.vue'),
        },
    },
    {
        path: '/maintain',
        name: 'maintain',
        components: {
            routerAdmin: () => import('./view/maintain/MaintainServer.vue'),
        },
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('./view/login/Login.vue'),
    },
];

const router = createRouter({
    history: createWebHashHistory(),
    routes,
});

app.use(ToastPlugin);
app.use(TippyPlugin);
app.use(TippyPlugin, {
    tippyDefaults: {},
});
app.use(VueClickAway);
app.use(VCalendar, {});
app.use(VueLazyLoad, {
    preLoad: 1.3,
    attempt: 1,
    listenEvents: ['scroll'],
});

app.use(router).mount('#app');
