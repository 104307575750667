const MResource = {
    VI: {
        MSG: {
            ERROR_EXCEPTION: 'Có lỗi xảy ra! Vui lòng liên hệ MISA để được hỗ trợ',
            CODE_NOTNUL: 'Mã không được để trống',
            NAME_NOTNULL: 'Tên không được để trống',
            UNIT_NOTNULL: 'Đơn vị không được để trống',
        },
        FORM_TITLE: 'Thông tin nhân viên',
        IS_CUSTOMER: 'Là khách hàng',
        IS_SUPPLIER: 'Là nhà cung cấp',
        DATA_CHANGED: 'Dữ liệu đã bị thay đổi. Bạn có muốn cất không?',
        REPLICATE_DATA: 'Nhân bản',
        DELETE_DATA: 'Xóa',
        DELETE_MULTIPLE: 'Xóa hàng loạt',
        STOP_USING: 'Ngừng sử dụng',
        BATCH_EXCUTION: 'Thực hiện hàng loạt',
        CONFIRM_DELETE_MULTIPLE: 'Bạn có thực sự muốn xóa những phản hồi đã chọn không?',
        IS_CHECKED: 'Đã chọn',
        UN_CHECKED: 'Bỏ chọn',
        TOAST: {
            ADD_SUCCESS: 'Thêm bản ghi mới thành công',
            EDIT_SUCCESS: 'Sửa thông tin bản ghi thành công',
            DELETE_SUCCESS: 'Xóa bản ghi thành công',
            TITLE_SUCCESS: 'Thành công',
            TITLE_ERROR: 'Lỗi',
            LOGIN_SUCCESS: 'Đăng nhập thành công',
        },
        DATA_TABLE: {
            EMPLOYEE_CODE: 'Mã nhân viên',
            FULL_NAME: 'Tên nhân viên',
            GENDER: 'Giới tính',
            DATE_OF_BIRTH: 'Ngày sinh',
            IDENTITY_NUMBER: 'Số CMND',
            POSITION: 'Chức danh',
            DEPARTMENT_NAME: 'Tên đơn vị',
            BANK_ACCOUNT: 'Số tài khoản',
            BANK_NAME: 'Tên ngân hàng',
            BRANCH: 'Chi nhánh TK ngân hàng',
            ACCOUNT_SYSTEM: {
                ACCOUNT_NUMBER: 'Số tài khoản',
                ACCOUNT_NAME: 'Tên tài khoản',
                NATURE_NAME: 'Tính chất',
                ACCOUNT_NAME_EN: 'Tên tiếng anh',
                EXPLAIN: 'Diễn giải',
                STATUS: 'Trạng thái',
            },
        },
        ACRONYM: {
            IDENTITY_NUMBER: 'Số chứng minh nhân dân',
            BRANCH: 'Chi nhánh tài khoản ngân hàng',
            RELOAD_DATA: 'Lấy lại dữ liệu',
            EXPORT_TO_EXCEL: 'Xuất ra Excel',
            HELP: 'Giúp',
            CLOSE: 'Đóng (ESC)',
            LANDLINEPHONE: 'Điện thoại cố định',
            MOBILEPHONE: 'Điện thoại di động',
            CUSTOM_UI: 'Tùy chỉnh giao diện',
            SAVE: 'Cất (Ctrl + S)',
            SAVE_AND_ADD: 'Cất và thêm (Ctrl + Shift + S)',
            OBJECT_THCP: 'Đối tượng tập hợp chi phí',
            EXPENSE_ITEM: 'Khoản mục chi phí',
        },
        MENU: {
            EMPLOYEE: 'Nhân viên',
            CATEGORY: 'Danh mục',
        },
        CATEGORIES: {
            OBJECT: 'Đối tượng',
            ACCOUNT: 'Tài khoản',
        },
        ACCOUNTS: {
            ACCOUNT_SYSTEM: 'Hệ thống tài khoản',
            ADD_ACCOUNT: 'Thêm tài khoản',
            FOREIGN_CURRENCY: 'Có hạch toán ngoại tệ',
            FOLLOW_DETAIL: 'Theo dõi chi tiết theo',
            OBJECT_THCP: 'Đối tượng THCP',
            ORDER: 'Đơn đặt hàng',
            PURCHASE_CONSTRACT: 'Hợp đồng mua',
            UNIT: 'Đơn vị',
            BANK_ACCOUNT: 'Tài khoản ngân hàng',
            CONSTRUCTION: 'Công trình',
            SELL_CONSTRACT: 'Hợp đồng bán',
            ITEMS_CP: 'Khoản mục CP',
            STATISTICAL_CODE: 'Mã thống kê',
            EDIT_ACCOUNT: 'Sửa tài khoản',
            VALIDATE_FORM: {
                NUMBER_NOTNULL: 'Số tài khoản không được để trống.',
                NAME_NOTNULL: 'Tên tài khoản không được để trống.',
                NATURE_NOTNULL: 'Tính chất không được để trống.',
                GENERAL_ACCOUNT_NOTNULL: 'Số tài khoản có độ dài > 3 ký tự phải điền thông tin <Tài khoản tổng hợp>',
                VALIDATE_DELETEROOTID: 'Bạn phải xóa tất cả các tài khoản con trước khi xóa tài khoản cha',
            },
        },
        BUTTONS: {
            ADD: 'Thêm',
            EXTEND: 'Mở rộng',
            INSTRUCTION: 'Hướng dẫn',
            ADD_ROW: 'Thêm dòng',
            DELETE_ALL_ROW: 'Xóa hết dòng',
            COLLAPSE: 'Thu gọn',
        },
        TAB_PROCEDURE: {
            PROCEDURE: 'Quy trình',
            PAY: 'Chi tiền',
            CASH_PROPERTY_BILLION: 'NGHIỆP VỤ TIỀN MẶT',
            COLLECT_MONEY: 'Thu tiền',
            FUN_INVENTORY: 'Kiểm kê quỹ',
            PAYMENT: {
                NAME_PAYMENT: 'Phiếu chi',
                OBJECT_NAME: 'Tên đối tượng',
                OBJECT_CODE: 'Mã đối tượng',
                RECIEVER: 'Người nhận',
                ADDRESS: 'Địa chỉ',
                REASON_PAY: 'Lý do chi',
                ATTACH: 'Kèm theo',
                ACCOUNTING_DATE: 'Ngày hạch toán',
                VOUCHER_DAY: 'Ngày phiếu chi',
                VOTES_CAST: 'Số phiếu chi',
                ORIGINAL_DOCUMENT: 'Chứng từ gốc',
                REFERENCE: 'Tham chiếu',
                TOTAL_MONEY: 'Tổng tiền',
                ACCOUNTING: 'Hạch toán',
            },
        },
    },
    EN: {
        MSG: {
            ERROR_EXCEPTION: 'Error!',
        },
    },
    // API_LOGIN: 'http://localhost:5001/admin',
    // API_LOGIN: 'https://4inarow.hntgaming.com/admin',
    API_LOGIN: 'https://dev.4inarow.online/admin',

    // API_ADMIN: 'http://localhost:5001/api/admin',
    // API_ADMIN: 'https://4inarow.hntgaming.com/api/admin',
    API_ADMIN: 'https://dev.4inarow.online/api/admin',
};
export default MResource;
